<template>
    <div>
        <el-row>
            <el-col :span="24" v-for="(item,index) in list" :key="index">
                <div class="xxlist">
                    <el-row>
                        <el-col :span="7">
                            <div class="xximage">
                                <el-image style="width:100%" :src="item.image"></el-image>
                            </div>
                        </el-col>
                        <el-col :span="17">
                            <div class="xxright" >
                                <div class="xxtitle">{{item.catalogue_title}}</div>
                                <div class="xxtitle2">{{item.catalogue_desc}}</div>
                                <div class="xxbtn" @click="xiazai(item.catalogue_id)">{{$t('download')}}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <el-dialog :title="$t('reg.mailbox')" :visible.sync="open" width="95%" >
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item :label="$t('reg.mailbox')" prop="email">
                    <el-input v-model="form.email"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="open = false">{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="submit()" :loading="btnload">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { catalogueList,sendYangce } from "@/api/api"; 
export default {
    data(){
        var checkEmail = (rule, value, callback) => {
            const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
            if (!value) {
                return callback(new Error(this.$t('login.notnull')))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error(this.$t('personal.emailmsg')))
                }
            }, 100)
        }
        return{
            list:[],
            open:false,
            form:{
                email:''
            },
            rules: {
                email:[
                    {  required: true,validator: checkEmail, trigger: 'blur' }
                ]
            },
            activeselect:undefined,
            btnload:false
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    created(){
        this.getlist()
    },
    methods:{
        async getlist(){
            const{data:res} = await catalogueList({notid:17})
            res.forEach((item,index)=>{
                item.image = process.env.VUE_APP_BASE_API+item.image
            })
            this.list = res
        },
        xiazai(catalogue_id){
            this.activeselect = catalogue_id
            this.open = true
        },
        submit(){
            this.$refs["form"].validate(valid => {
                if(valid){
                    this.form.catalogue_id = this.activeselect
                    this.btnload = true
                    sendYangce(this.form).then(res=>{
                        this.btnload = false
                        this.$message.success('Success 成功')
                        this.open = false
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .xxlist{
        margin-top: 15px;
        margin-left: 15px;
    }
    .xxright{
        margin-left: 20px;
        .xxtitle{
            margin-top: 5px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            font-weight: bold;
        }
        .xxtitle2{
            margin-top: 5px;
            font-size: 14px;
            line-height: 20px;
            color: rgb(0, 0, 0,0.8);
            height: 65px;
        }
        .xxbtn{
            width: 70px;
            height: 34px;
            background: #0052D9;
            color: #fff;
            text-align: center;
            line-height: 34px;
            font-size: 14px;
            cursor: pointer;
        }
    }
</style>